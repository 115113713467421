import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

const ComingSoon = () => {
    const calculateTimeLeft = () => {
        const difference = +new Date('2025-02-01') - +new Date();
        let timeLeft = {};

        if (difference > 0) {
            timeLeft = {
                days: Math.floor(difference / (1000 * 60 * 60 * 24)),
                hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
                minutes: Math.floor((difference / 1000 / 60) % 60),
                seconds: Math.floor((difference / 1000) % 60),
            };
        }

        return timeLeft;
    };

    const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

    useEffect(() => {
        const timer = setInterval(() => {
            setTimeLeft(calculateTimeLeft());
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    const timerComponents = [];

    Object.keys(timeLeft).forEach((interval) => {
        if (!timeLeft[interval]) {
            return;
        }

        timerComponents.push(
            <span key={interval} className="mx-2">
                <span className="fs-3 fw-bold">{timeLeft[interval]}</span> <small>{interval}</small>
            </span>
        );
    });

    return (
        <div
            className="d-flex flex-column justify-content-center align-items-center min-vh-100 text-light text-center"
            style={{
                backgroundImage: "url('https://t4.ftcdn.net/jpg/05/55/82/51/360_F_555825144_uwj46ckT8THX48VRYFdrhBQPL1hHnku5.jpg')",
                backgroundSize: 'cover',
                backgroundPosition: 'center',
                backgroundRepeat: 'no-repeat',
                position: 'relative',
                overflow: 'hidden',
                borderRadius: '10px',
            }}
        >
            <div
                style={{
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    backgroundColor: 'rgba(0, 0, 0, 0.5)',
                    zIndex: 1,
                }}
            ></div>
            <div style={{ position: 'relative', zIndex: 2 }}>
                <h1 className="display-4 mb-4 text-white">Game Coming Soon</h1>
                <h2 className="mb-3 text-white">Countdown to Launch</h2>
                <div className="d-flex justify-content-center align-items-center fs-5  text-white">
                    {timerComponents.length ? timerComponents : <span>Time's up!</span>}
                </div>
            </div>
        </div>
    );
};

export default ComingSoon;
