import React, { useState, useRef, useEffect } from "react";
import PageTitle from "../../../layouts/PageTitle";
import { Row, Col, Card, Carousel, Accordion, ButtonGroup, Button } from 'react-bootstrap';
import { GlobalSet } from '../../global/GlobalProvider';
import { Link } from "react-router-dom";
import axios from "axios";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';

const BasicDatatable = () => {
  const [data, setData] = useState(document.querySelectorAll("#job_data tbody tr"));
  const sort = 5;
  const activePag = useRef(0);
  const { CurrencySymbol } = GlobalSet();
  const [selectedPosition, setSelectedPosition] = useState(""); // State to track the selected position
  
  const chageData = (frist, sec) => {
    for (var i = 0; i < data.length; ++i) {
      if (i >= frist && i < sec) {
        data[i].classList.remove("d-none");
      } else {
        data[i].classList.add("d-none");
      }
    }
  };

  useEffect(() => {
    setData(document.querySelectorAll("#job_data tbody tr"));
  }, []);

  activePag.current === 0 && chageData(0, sort);

  let paggination = Array(Math.ceil(data.length / sort))
    .fill()
    .map((_, i) => i + 1);

  const onClick = (i) => {
    activePag.current = i;
    chageData(activePag.current * sort, (activePag.current + 1) * sort);
  };

  /*.....income data......*/

  const { t } = useTranslation();
  const [income, setIncome] = useState([]);

  useEffect(() => {
    const selectedCurrency = localStorage.getItem('selectedCurrency');
    const auth_token = localStorage.getItem('auth_token');
    const data = {
      key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      currency: selectedCurrency,
    };
    if (auth_token !== null) {
      axios.post('/smart-team', data)
        .then(response => {
          setIncome(response.data.data);
          console.log(response.data.data);
        })
        .catch(error => {
          console.log(error);
        });
    }
  }, []);

  // Filter income by selectedPosition
  const filteredIncome = selectedPosition
    ? income.filter(incomerow => incomerow.team_position === selectedPosition)
    : income;

  return (
    <div className="col-12">
      <div className="card">
        <div className="card-body">
          <Col xl="12" className="p-0">
            <div className="App">
              <div className="table-responsive card booking">
                <table
                  className="display w-100 dataTable"
                  id="example5"
                  role="grid"
                  aria-describedby="example5_info"
                  style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', background: '#01273f' }}
                >
                  <tbody>
                    <tr className="odd" role="row">
                      <Link to="/all-team-users"><td className="text-white"> All Users </td></Link> 
                       <td className="text-white" onClick={() => setSelectedPosition("")}><Link className="text-white" to="/my-smart-team">Position Level</Link></td>
                      <td className="text-white" onClick={() => setSelectedPosition("a")}>Position A</td>
                      <td className="text-white" onClick={() => setSelectedPosition("b")}>Position B</td>
                      <td className="text-white" onClick={() => setSelectedPosition("c")}>Position C</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <div className="table-responsive">
            <div id="job_data" className="dataTables_wrapper">
              <table
                className="display w-100 dataTable"
                id="example5"
                role="grid"
                aria-describedby="example5_info"
              >
                <thead>
                  <tr role="row">
                    <th className="sorting_asc" style={{ width: "278px" }}>SL</th>
                    <th className="sorting" style={{ width: "278px" }}>{t('pro_his_bet_type')}</th>
                    <th className="sorting" style={{ width: "278px" }}>Username</th>
                    <th className="sorting" style={{ width: "278px" }}>Team Position</th>
                    <th className="sorting" style={{ width: "278px" }}>{t('pro_his_odds_name')}</th>
                    <th className="sorting" style={{ width: "278px" }}>Status</th>
                    <th className="sorting" style={{ width: "278px" }}>Self</th>
                    <th className="sorting" style={{ width: "278px" }}>Team Community</th>
                    <th className="sorting" style={{ width: "278px" }}>Team Sells</th>
                    <th className="sorting" style={{ width: "278px" }}>Join Date</th>
                    <th className="sorting" style={{ width: "278px" }}>Deta</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredIncome.map((incomerow, index) => (
                    <tr key={incomerow.id} className="odd" role="row">
                      <td className="sorting_1">{index + 1}</td>
                      <td className="sorting_1">{incomerow.email}</td>
                      <td className="sorting_1">{incomerow.username}</td>
                      <td className="sorting_1">{incomerow.team_position}</td>
                      <td>{incomerow.count_active_spons} Members</td>
                      <td>{incomerow.total_invest === 0 ? 'Inactive' : 'Active'}</td>
                      <td>${incomerow.investing_wallet}</td>
                      <td>{ parseFloat(parseFloat(incomerow.team_sell / 20).toFixed(0))} Communitys
                      </td>
                      <td>${incomerow.team_sell}</td>
                      <td>{incomerow.created_at}</td>
                     <td> <Link className="btn btn-secondary" to={"/view-team/"+ incomerow.id +"/level-g"}>Next </Link></td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <div className="d-sm-flex text-center justify-content-between align-items-center mt-3 mb-md-0 mb-2">
                <div className="dataTables_info">
                  Showing {activePag.current * sort + 1} to{" "}
                  {data.length > (activePag.current + 1) * sort
                    ? (activePag.current + 1) * sort
                    : data.length}{" "}
                  of {data.length} entries
                </div>
                <div
                  className="dataTables_paginate paging_simple_numbers mb-0"
                  id="example5_paginate"
                >
                  <Link
                    className="paginate_button previous disabled"
                    to="/my-smart-team"
                    onClick={() =>
                      activePag.current > 0 && onClick(activePag.current - 1)
                    }
                  >
                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                  </Link>
                  <span>
                    {paggination.map((number, i) => (
                      <Link
                        key={i}
                        to="/my-smart-team"
                        className={`paginate_button  ${activePag.current === i ? "current" : ""} `}
                        onClick={() => onClick(i)}
                      >
                        {number}
                      </Link>
                    ))}
                  </span>
                  <Link
                    className="paginate_button next"
                    to="/my-smart-team"
                    onClick={() =>
                      activePag.current + 1 < paggination.length &&
                      onClick(activePag.current + 1)
                    }
                  >
                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BasicDatatable;
