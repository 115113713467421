import React, {  useReducer,useState,useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { GlobalSet } from '../../global/GlobalProvider';
/// Image 
import loading_img from "../../../../images/profile/loading.gif";
import { Dropdown, Button, Modal } from "react-bootstrap";
import { SRLWrapper } from "simple-react-lightbox";
//lg
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';


const initialState = false;
const reducer = (state, action) =>{
	switch (action.type){
		case 'sendMessageOpen':
			return { ...state, sendMessage: true }
		case 'sendMessageClose':			
			return { ...state, sendMessage: false }		
			
		default:
            return state	
	}	
}
 
const PostDetails = () => {
  const { t } = useTranslation();
  //const [sendMessage, setSendMessage] = useState(false);
  const [state, dispatch] = useReducer(reducer, initialState);
	//console.log('state');
	//console.log(state);
	
  const options = {
    settings: {
      overlayColor: "#000000",
    },
  };

   const { BaseUrl } = GlobalSet();
   const [loading, setLoading] = useState(true);
   const [getway, setGetway] = useState([]);

    useEffect(()=>{
        
      const data = {
        key_s: 'eyJ0eXAiOiJKV1Qidfg%!#%$%LCdf#*&*JhbGciOiJSUzI1NiJ9',
      }
        axios.post('/apps-deposit',data)
        .then(response=>{
           setGetway(response.data.data)
           setLoading(false);
         })
     .catch(error=>{
            
           console.log(error)
       })

   },[]);


  return (
    <div>
      <div>
       <div className="col-xl-12">
			<div className="row">

           {getway.map((getwayitem)=>(
            <div key={getwayitem.id} className="col-lg-12">
              <div>
{/*               <div style={{background: "#01273f"}} className="card-header border-0 pt-2 pb-2">
                  <h5 className="text-white">{getwayitem.payment_type}</h5>
                  <img src={BaseUrl+(getwayitem.photo)} alt="" className="me-3 rounded" width={75} height={30}/>
                </div>  */}

                          <div className="row pt-5 pb-0 p-3">
                            {getwayitem.allmatch.map((element)=>(
                                <div className="col-xl-6 col-xxl-6 col-lg-6 col-md-6 col-sm-6 col-6 int-col p-1">   
                                      <div key={element.id}>

  
                              <Link key={element.id} to={
                                element.ap_type==='local' 
                                   ? "local-deposit-view/"+element.id+"/"+element.slug
                                : element.ap_type==='newpayment'
                                   ? "crypto-deposit-view/"+element.id+"/"+element.slug
                                :
                                "casino-providers"

                                  }>
                                            <div className="card mb-0">
                                            <div className="img-bx text-center ">
                                                <img src={BaseUrl+(element.photo)} alt="" className="me-3 m-3 card-list-img" width="35%" />
                                                <h5 className="nav-text text-center p-1 rounded-bottom" style={{display: "block", background: "#01273f", color: "white"}}> {element.sort_discription}</h5>

                                            </div>  
                                            </div>  
                                         </Link>
                                      </div>  
                                  </div>  
                                  ))}
                           </div>   
              </div>
            </div>
        
        ))}
			</div>	
        </div>	
         
      </div>
    </div>
  );
};

export default PostDetails;
